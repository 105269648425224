import React from "react"
import rainbow from "../images/skala.svg"

const Leistung = () => {
  return (
    <section id="Leistung" className="leistung">
      <div className="container">
        <div className="section__title">– Leistung –</div>
        <h2>Ihr Partner für B&auml;ckerei-K&auml;ltetechnik</h2>
        <p className="teaser">
          Zwischen Tiefk&uuml;̈hlen und G&auml;ren k&ouml;nnen viele Fehler passieren, die Ihr
          Produkt beeinflussen. Ich helfe Ihnen, sie zu verhindern oder
          schnell zu beheben. Meine Leistungen erstrecken sich über die
          gesamte Verfahrenstechnik in B&auml;ckereien.
        </p>
        <div className="section__content">
          <div className="leistung__items">
            <div className="leistung__item">
              <h3 className="blue">Tiefkühlen</h3>
              <p>
                Sie stellen plötzlich fest, dass Ihr Tiefkühler nicht mehr
                schnell genug kühlt. Im Raum riecht es komisch. Die Ware ist
                oberflächlich abgetrocknet.
              </p>
            </div>
            <div className="leistung__item">
              <h3 className="purple">Lagern</h3>
              <p>
                In dieser Phase passieren die meisten Fehler. Das Produkt
                befindet sich im Temperaturbereich zwischen Tiefkühlen und
                Auftauen, die Gefahr des Feuchtigkeitsverlusts an der
                Oberfläche ist jetzt am größten.
              </p>
            </div>
            <div className="leistung__item">
              <h3 className="yellow">G&auml;ren</h3>
              <p>
                Ihre Ware hat im Sticken unterschiedliche Garstände, ist an
                manchen Stellen verhautet und an anderen nicht?
              </p>
            </div>
          </div>
          <div className="leistung__image">
            <img src={rainbow} alt={"Skala Illustration Leistungen"} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Leistung
