import React from 'react';
import profil from "../images/profilbild.png"

const Erfahrung = () => {
    return (
      <section id="Erfahrung" className="erfahrung">
        <div className="container">
          <div className="section__title">– Erfahrung –</div>
          <h2>Von der B&auml;ckerlehre zum Kühltechnik-meister</h2>
          <p className="teaser">
            Ich bin in einer Bäckerei groß geworden und habe den B&auml;ckerberuf von der Pieke auf erlernt.
            Später wurden die Berufe technischer, und seit 2015 bin ich K&auml;lteanlagenbauermeister. Dieser umfassende
            Erfahrungsschatz macht mich zu Ihrem perfekten (technischen)
            B&auml;cker-Partner.
          </p>
          <div className="section__content">
            <div className="erfahrung__person">
              <div className="profil">
                <img src={profil} alt="Profilbild: Roland Vielhaber" />
                <div>
                  <div className="name yellow">Roland Vielhaber</div>
                  <p>K&auml;lteanlagenbauermeister und gelernter B&auml;cker</p>
                </div>
              </div>
              <div className="halb-halb">
                <div className="halb-halb__item">
                  <h3 className="yellow">halb B&auml;cker </h3>
                  <p>
                    Schon seit meiner Kindheit fasziniert mich der Beruf des
                    B&auml;ckers so sehr, dass ich ihn sp&auml;ter selbst erlernt habe.
                    Heute ist das Wissen um das Handwerk die Grundlage
                    meines Berufs.
                  </p>
                </div>
                <div className="halb-halb__item">
                  <h3 className="blue">halb K&auml;ltetechniker</h3>
                  <p>
                    Ich war lange Anlagenführer in der Getr&auml;nkeindustrie und
                    Servicetechniker für B&auml;ckereimaschinen bei WP, bis ich
                    2015 den K&auml;lteanlagenbauermeister machte.
                  </p>
                </div>
              </div>
            </div>
            <div className="erfahrung__werdegang timeline">
              <div>
                <div className="timeline__header">1982</div>
                <div className="timeline__content">
                  <a target="_blank" href="/documents/gesellenbrief.pdf">
                    B&auml;ckergeselle
                  </a>
                </div>
              </div>
              <div>
                <div className="timeline__header">1993 – 1995</div>
                <div className="timeline__content">
                  <a
                    target="_blank"
                    href="/documents/prozessleitelektroniker.pdf"
                  >
                    Prozessleiterelektroniker
                  </a>
                  <a target="_blank" href="/documents/sps_techniker.pdf">
                    SPS-Techniker
                  </a>
                </div>
              </div>
              <div>
                <div className="timeline__header">1995 – 1998</div>
                <div className="timeline__content">
                  Anlagenführer in der Getr&auml;nkeindustrie
                </div>
              </div>
              <div>
                <div className="timeline__header">1998 – 2013</div>
                <div className="timeline__content">
                  <a target="_blank" href="/documents/diplom.pdf">
                    Servicetechniker für B&auml;ckereimaschinen
                  </a>
                </div>
              </div>
              <div>
                <div className="timeline__header">2013 – heute</div>
                <div className="timeline__content">
                  <strong className="blue">Partner der B&auml;cker</strong>
                  <a target="_blank" href="/documents/handwerksrolle.pdf">
                    Meisterbrief
                  </a>
                  <a target="_blank" href="/documents/co2.pdf">
                    CO2-Zertifikat
                  </a>
                  <a target="_blank" href="/documents/firmenzertifikat.pdf">
                    Betriebszertifikat
                  </a>
                  <a target="_blank" href="/documents/propangas.pdf">
                    Propanzertifikat
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
};

export default Erfahrung;