import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Leistung from "../components/leistung";
import Slider from "../components/contentslider";
import Erfahrung from "../components/erfahrung";
import Referenzen from "../components/referenzen";
import Notdienst from "../components/notdienst";
import Kontakt from "../components/kontakt";

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `b&auml;ckerei`,
        `k&auml;lteservice`,
        `klimaanlage`,
        `notdienst`,
        `nieheim`,
        `roland vielhaber`,
      ]}
    />
    <Slider />
    <Leistung />
    <Erfahrung />
    {/* <Referenzen /> */}
    <Notdienst />
    <Kontakt />
  </Layout>
)

export default IndexPage
