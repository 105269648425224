import React from 'react';
import Slider from "react-slick"
import ImageOne from "../images/slider1.jpg"
import ImageTwo from "../images/slider2.jpg"

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  fade: true,
  autoplay: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 6500,
};

const ContentSlider = () => {
  return (
    <section className="slider">
      <div className="container">
        <Slider {...settings}>
          <div>
            <div
              style={{ backgroundImage: `url(${ImageOne})` }}
              className="slider__item"
            >
              <div className="slider__content">
                <h2>Ihr Premiumprodukt Br&ouml;tchen</h2>
                <p>
                  Bei Problemen mit den Br&ouml;tchen brauchen Sie mehr als
                  einen Techniker. Ich berate Sie vom Mehl bis zum
                  knusprigen Produkt in der Auslage.
                  </p>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{ backgroundImage: `url(${ImageTwo})` }}
              className="slider__item"
            >
              <div className="slider__content">
                <h2>DIE TECHNIK ZUM GEB&Auml;CK</h2>
                <p>
                  Als Ba&auml;cker, Elektroniker und Kälteanlagenbauer mit
                  vielen Jahren Erfahrung im WP-Service bin ich Ihr
                    Spezialist und Problemlo&ouml;ser{" "}
                </p>
              </div>
            </div>
          </div>
        </Slider>
        <div className="sticker">
          <div>
            Über <span>30 Jahre</span>
          </div>
          <p>ERFAHRUNG IN B&Auml;CKERHANDWERK UND K&Uuml;HLTECHNIK</p>
        </div>
      </div>
    </section>
  )
};

export default ContentSlider