import React from 'react';

const Kontakt = () => {
    return (
      <section id="Kontakt" className="kontakt">
        <div className="container">
          <div className="section__title">– Kontakt –</div>
          <h2>
            Egal, ob Br&ouml;tchen, Schrippen oder Semmeln – ich bin in Ganz
            deutschland unterwegs
          </h2>
          <div className="section__content section__content--taller">
            <div className="name">
              Roland Vielhaber – Partner der B&auml;cker
            </div>
            Sch&ouml;nenberg 1 <br />
            33039 Nieheim <br />
            Mobil:{" "}
            <a className="phone" href="tel:+491726820240">
              0172 - 68 20 24 0
            </a>{" "}
            <br />
            E-Mail:{" "}
            <a href="mailto:info@partner-der-baecker.de ">
              info@partner-der-baecker.de
            </a>
            <br />
          </div>
        </div>
      </section>
    )
};

export default Kontakt;