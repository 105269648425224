import React from "react"
import alarm from "../images/alarm.svg"
import tele from "../images/tele.png"
import mail from "../images/mail.png"

const Notdienst = () => {
  return (
    <section id="Notdienst" className="notdienst">
      <img src={alarm} alt="Notdienst Icon" />
      <div className="container">
        <div className="section__title">
          24 Stunden NOTDIENST in Deutschland
        </div>
        <h2>
          Schrumpeln die Schrippen?
          <br />
          Gemeinsam lassen wir ihre Produkte aufgehen.
        </h2>
        <p className="teaser">
          Ist Ihre Kühlanlage ausgefallen? Gibt es Probleme mit dem Ofen? Ich
          bin für Sie da – immer!
        </p>
        <div className="section__content section__content--taller">
          <div className="name">Roland Vielhaber</div>
          <a className="phone" href="tel:+491726820240">
            <img width="20" height="20" src={tele} alt="Notdienst Icon" />
            0172 – 68 20 24 0
          </a>
          <a className="mail" href="mailto:notdienst@partner-der-baecker.de">
            <img width="32" height="21" src={mail} alt="Notdienst Icon" />
            notdienst@partner-der-baecker.de
          </a>
        </div>
      </div>
    </section>
  )
}

export default Notdienst
